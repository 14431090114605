@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
body {
  font-family: "Roboto", sans-serif;
  background-color: #212032;
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: "transparent";
  /* #212032; */
}

::-webkit-scrollbar-thumb {
  background: #1178f880;
}

::-webkit-scrollbar-thumb:hover {
  background: #1178f8;
}

.video-cover video {
  object-fit: cover;
}

.video-contain video {
  object-fit: contain;
}

/* .MuiTooltip-tooltip {
  background-color: aliceblue;
  color: black;
} */

.MuiSlider-valueLabel {
  color: transparent;
  font-weight: 600 !important;
  font-size: 1rem !important;
  margin-top: 14px;
}

input[type="time"]::-webkit-calendar-picker-indicator{
  display: none;
  filter:invert(97%) sepia(100%) saturate(0%) hue-rotate(129deg) brightness(110%) contrast(102%);
  }

input[type="time"]:focus-visible{
    outline: none;
  }